import Index from './components/Index.vue';
import Rookie from './components/Rookie.vue';
import Story from './components/Story.vue';
import Category from './components/Category.vue';
import Carousel from './components/Carousel.vue';
import Update from './components/Update.vue';

export const routes = [
    {
        name: 'Index',
        path: '/',
        component: Index,
    }, {
        name: 'Rookie',
        path: '/rookie',
        component: Rookie,
    }, {
        name: 'Story',
        path: '/story',
        component: Story,
    }, {
        name: 'Category',
        path: '/category',
        component: Category,
    }, {
        name: 'Update',
        path: '/update',
        component: Update,
    }, {
        name: 'Carousel',
        path: '/carousel',
        component: Carousel,
    },
];
<template>
    <el-dialog v-model="dialog_opened" :before-close="close" width="80%">
        <vue-draggable-next v-model="selected" :animation="300" handle=".handle" @change="sort">
            <transition-group>
                <div class="handle" style="display: inline-block;position: relative" v-for="row in selected">
                    <el-image style="width: 140px;margin: 5px;cursor: pointer" lazy
                              :src="`${$root.cdn_url}/${row.thumbnail}`"></el-image>
                    <el-icon @click="open(row)" size="20"
                             style="position: absolute; top: 5px; right: 25px;cursor: pointer;color: var(--el-color-warning)">
                        <Edit/>
                    </el-icon>
                    <el-icon @click="remove(row)" size="20"
                             style="position: absolute; top: 5px; right: 5px;cursor: pointer;color: var(--el-color-danger)">
                        <DeleteFilled/>
                    </el-icon>
                </div>
            </transition-group>
        </vue-draggable-next>
        <el-divider></el-divider>
        <el-table :data="data" v-loading="loading" style="margin-top: 10px">
            <el-table-column label="ID" prop="id" width="250">
                <template #default="scope">
                    <div>{{ scope.row.id }}</div>
                    <div style="text-align: center">
                        <a :href="`${$root.cdn_url}/${scope.row.resource}`" target="_blank">查看原图</a>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="缩略图" prop="thumbnail" width="200">
                <template #default="scope">
                    <el-image :src="`${$root.cdn_url}/${scope.row.thumbnail}`" lazy style="width: 180px"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="模块" prop="module" width="100">
                <template #default="scope">
                    {{ $root.image_module_enum[scope.row.module] }}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
                <template #default="scope">
                    <el-button type="primary" size="small" :disabled="scope.row.disabled || loading"
                               @click="create(scope.row)">加入
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination layout="prev, pager, next" :total="total" @current-change="fetchData"
                       :page-size="query.limit"></el-pagination>
        <template #footer>
            <el-button type="primary" @click="close" :disabled="loading">关闭</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="dialog_opened1" :before-close="reset">
        <el-form :model="form" ref="form" label-width="120px">
            <el-form-item :label="`${k}标题`" v-for="(_, k) in form.i18n_title" :prop="`i18n_title.${k}`"
                          :rules="k==='EN'?[{required: true, message: `请输入${k}标题`}]:null">
                <el-input v-model="form.i18n_title[k]" :disabled="loading"
                          :style="{width: k==='EN'?`calc(100% - ${loading?'108':'88'}px)`:'100%'}"></el-input>
                <el-button v-if="k==='EN'" :loading="loading" @click="translateTitle">自动翻译</el-button>
            </el-form-item>
            <el-form-item :label="`${k}描述`" v-for="(_, k) in form.i18n_desc" :prop="`i18n_desc.${k}`"
                          :rules="k==='EN'?[{required: true, message: `请输入${k}描述`}]:null">
                <el-input v-model="form.i18n_desc[k]" :disabled="loading"
                          :style="{width: k==='EN'?`calc(100% - ${loading?'108':'88'}px)`:'100%'}"></el-input>
                <el-button v-if="k==='EN'" :loading="loading" @click="translateDesc">自动翻译</el-button>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {initI18n} from "../libs/init";
import {translateI18n, update} from "../libs/utils";
import {VueDraggableNext} from 'vue-draggable-next'

export default {
    name: "StoryImage",
    components: {VueDraggableNext},
    data() {
        return {
            loading: false, story: null, dialog_opened: false, selected: [], total: 0, data: [], dialog_opened1: false,
            editing: null,
            query: {
                module: 3,
                skip: 0,
                limit: 100,
            },
            form: {
                image_id: '',
                story_id: '',
                i18n_title: initI18n(),
                i18n_desc: initI18n(),
            }
        }
    },
    methods: {
        init(story) {
            this.story = story;
            this.loading = this.dialog_opened = true;
            axios.all([
                axios.get(`/cms/v1/story-image`, {params: {story_id: story.id}}).then(res => {
                    this.selected = res.data.data.imageList;
                    this.selected.sort(function (a, b) {
                        return a.sort - b.sort;
                    });
                }),
                axios.get(`/cms/v1/image`, {params: this.query}).then(res => {
                    this.data = res.data.data.imageList;
                    this.data.forEach(d => {
                        this.selected.forEach(s => {
                            if (d.id === s.image_id) {
                                d.disabled = true;
                            }
                        })
                    });
                    this.total = res.data.data.total;
                })
            ]).then(_ => {
                this.loading = false;
            })
        },
        close() {
            this.story = null;
            this.loading = this.dialog_opened = false;
        },
        reset() {
            this.$refs.form.resetFields();
            this.form.i18n_title = initI18n();
            this.form.i18n_desc = initI18n();
            this.loading = this.dialog_opened1 = false;
            this.editing = null;
        },
        fetchData(page) {
            this.query.skip = page - 1;
            this.loading = true;
            axios.get(`/cms/v1/image`, {params: this.query}).then(res => {
                this.data = res.data.data.imageList;
                this.data.forEach(d => {
                    this.selected.forEach(s => {
                        if (d.id === s.image_id) {
                            d.disabled = true;
                        }
                    })
                });
                this.total = res.data.data.total;
                this.loading = false;
            })
        },
        create(image) {
            this.dialog_opened1 = true;
            this.form.image_id = image.id;
            this.form.story_id = this.story.id;
        },
        open(row) {
            this.dialog_opened1 = true;
            this.editing = row;
            update(this.form, row);
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/story-image/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/story-image`, this.form).then(res => {
                            this.selected.push(res.data.data);
                            this.data.forEach(d => {
                                if (d.id === this.form.image_id) {
                                    d.disabled = true;
                                }
                            });
                            this.reset();
                        })
                    }
                }
            })
        },
        remove(row) {
            this.loading = true;
            axios.delete(`/cms/v1/story-image/${row.id}`).then(res => {
                this.selected.splice(this.selected.indexOf(row), 1);
                this.loading = false;
            });
        },
        sort() {
            const sort = [];
            this.selected.forEach((d, i) => {
                sort.push(d.id);
                d.sort = i;
            });
            this.loading = true;
            axios.post(`/cms/v1/sort/story-image`, {sort}).then(res => {
                this.loading = false;
            })
        },
        translateTitle() {
            this.loading = true;
            translateI18n(this.form.i18n_title.EN, this.form.i18n_title).then(_ => {
                this.loading = false;
            });
        },
        translateDesc() {
            this.loading = true;
            translateI18n(this.form.i18n_desc.EN, this.form.i18n_desc).then(_ => {
                this.loading = false;
            });
        },
    }
}
</script>

<style scoped>

</style>
<template>
    <div>
        <el-button type="primary" :loading="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-table :data="data" v-loading="loading" style="margin-top: 10px">
        <el-table-column label="ID" prop="id" width="250"></el-table-column>
        <el-table-column label="封面" prop="resource" width="150">
            <template #default="scope">
                <el-image :src="`${$root.cdn_url}/${scope.row.resource}`" lazy style="width: 100px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="名称" prop="i18n_name.ZH_HANS" width="150"></el-table-column>
        <el-table-column label="上线时间" prop="online_date" width="160"></el-table-column>
        <el-table-column label="day" prop="day" width="100"></el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button :disabled="loading" size="small" type="success" @click="$refs.storyImage.init(scope.row)">内容
                </el-button>
                <el-button :disabled="loading" size="small" @click="openDialog(scope.row)">编辑</el-button>
                <el-button :disabled="loading" size="small" @click="remove(scope.row)" type="danger">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" ref="form" label-width="120px">
            <el-form-item :label="`${k}名称`" v-for="(_, k) in form.i18n_name" :prop="`i18n_name.${k}`"
                          :rules="k==='EN'?[{required: true, message: `请输入${k}名称`}]:null">
                <el-input v-model="form.i18n_name[k]" :disabled="loading"
                          :style="{width: k==='EN'?`calc(100% - ${loading?'108':'88'}px)`:'100%'}"></el-input>
                <el-button v-if="k==='EN'" @click="translate" :loading="loading">自动翻译</el-button>
            </el-form-item>
            <el-form-item label="封面图" prop="resource" :rules="[{required: true, message: '请上传封面图'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                           accept="image/*" :data="{prefix: 'story'}" :onProgress="handleProgress" :disabled="loading"
                           :headers="{authorization: $root.authorization}">
                    <img v-if="form.resource" :src="`${$root.cdn_url}/${form.resource}`"
                         style="width: 200px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="上线时间" prop="online_date">
                <el-date-picker v-model="form.online_date" :disabled="loading" clearable
                                value-format="YYYY-MM-DD"></el-date-picker>
            </el-form-item>
            <el-form-item label="day" prop="day">
                <el-input-number v-model="form.day" :disabled="loading"></el-input-number>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
    <story-image ref="storyImage"></story-image>
</template>

<script>
import axios from "ts-axios-new";
import {initI18n} from "../libs/init";
import {update, translateI18n} from "../libs/utils";
import {ElMessageBox} from 'element-plus'
import StoryImage from "./StoryImage";

export default {
    name: "Story",
    components: {StoryImage},
    data() {
        return {
            loading: false, data: [], dialog_opened: false, editing: null,
            form: {
                i18n_name: initI18n(),
                resource: '',
                online_date: null,
                day: null,
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get('/cms/v1/story').then(res => {
                this.data = res.data.data.storyList;
                this.loading = false;
            });
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            });
        },
        reset() {
            this.$refs.form.resetFields();
            this.form.i18n_name = initI18n();
            this.dialog_opened = this.loading = false;
            this.editing = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/story/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/story`, this.form).then(res => {
                            this.data.unshift(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/story/${row.id}`).then(_ => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                });
            }).catch(_ => {
            });
        },
        handleProgress() {
            this.loading = true;
        },
        handleSuccess(res) {
            this.form.resource = res.data.name;
            this.loading = false;
        },
        translate() {
            this.loading = true;
            translateI18n(this.form.i18n_name.EN, this.form.i18n_name).then(_ => {
                this.loading = false;
            });
        },
    },
    mounted() {
        this.init();
    },
}
</script>

<style scoped>

</style>
<template>
    <el-calendar ref="calendar" v-loading="loading" v-model="date">
        <template #header="{date}">
            <span>{{ date }}</span>
            <el-button-group>
                <el-button size="small" @click="selectDate('prev-year')">
                    Previous Year
                </el-button>
                <el-button size="small" @click="selectDate('prev-month')">
                    Previous Month
                </el-button>
                <el-button size="small" @click="selectDate('today')">Today</el-button>
                <el-button size="small" @click="selectDate('next-month')">
                    Next Month
                </el-button>
                <el-button size="small" @click="selectDate('next-year')">
                    Next Year
                </el-button>
            </el-button-group>
        </template>
        <template #date-cell="{data: _data}">
            <div>{{ _data.day.split('-').slice(1).join('-') }}</div>
            <div style="height: 70px;" @click="openDialog(_data.day)">
                <template
                    v-for="d in data.filter(d=>d.online_date===_data.day).sort((a, b) => a.sort < b.sort ?-1:1).slice(0, 6)">
                    <el-image :src="`${$root.cdn_url}/${d.thumbnail}`" style="height: 30px;margin: 2px" lazy></el-image>
                </template>
            </div>
        </template>
    </el-calendar>
    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%">
        <vue-draggable-next v-model="selected" :animation="300" handle=".handle" @change="sort">
            <transition-group>
                <div style="display: inline-block;position: relative;width: 140px;margin:5px" v-for="image in selected">
                    <el-image style="cursor: pointer;display: block" lazy
                              :src="`${$root.cdn_url}/${image.thumbnail}`" class="handle"></el-image>
                    <b style="word-break: break-word">{{ image.id }}</b>
                    <el-icon @click="offline(image)" size="20"
                             style="position: absolute; top: 5px; right: 5px;cursor: pointer;color: var(--el-color-danger)">
                        <DeleteFilled/>
                    </el-icon>
                    <el-icon @click="updateVideo(image)" size="20"
                             style="position: absolute;left: 10px;top: 110px;cursor: pointer">
                        <Lock style="color: var(--el-color-primary)" v-if="image.video"/>
                        <Unlock style="color: var(--el-color-info)" v-else/>
                    </el-icon>
                </div>
            </transition-group>
        </vue-draggable-next>
        <el-divider></el-divider>
        <div>
            <el-select :disabled="loading" v-model="query.category" clearable filterable style="width: 200px"
                       placeholder="分类">
                <el-option v-for="c in category_list" :label="`${c.i18n_name.EN}(${c.i18n_name.ZH_HANS})`"
                           :value="c.key"></el-option>
            </el-select>
            <el-select :disabled="loading" v-model="query.type" clearable style="width: 100px" placeholder="类型">
                <el-option v-for="(v, k) in $root.image_type_enum" :label="v" :value="parseInt(k)"></el-option>
            </el-select>
            <el-select :disabled="loading" v-model="query.video" clearable style="width: 100px" placeholder="激励视频">
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
            </el-select>
            <el-select :disabled="loading" v-model="query.online" clearable style="width: 200px" placeholder="已选上线时间">
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
            </el-select>
            <el-input :disabled="loading" v-model="query.id" style="width: 100px" placeholder="备注"></el-input>
            <el-button type="success" :loading="loading" @click="fetchData(1)">查询</el-button>
        </div>
        <el-table :data="page_data" v-loading="loading" style="margin-top: 10px">
            <el-table-column label="ID" prop="id" width="250">
                <template #default="scope">
                    <div>{{ scope.row.id }}</div>
                    <div style="text-align: center">
                        <a :href="`${$root.cdn_url}/${scope.row.resource}`" target="_blank">查看原图</a>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="缩略图" prop="thumbnail" width="100">
                <template #default="scope">
                    <el-image :src="`${$root.cdn_url}/${scope.row.thumbnail}`" lazy style="width: 90px"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="模块" prop="module" width="100">
                <template #default="scope">
                    {{ $root.image_module_enum[scope.row.module] }}
                </template>
            </el-table-column>
            <el-table-column label="分类" prop="categories" width="200">
                <template #default="scope">
                    <template v-for="key in scope.row.categories">
                        <template v-for="c in category_list">
                            <el-tag v-if="c.key === key">{{ c.i18n_name.EN }}</el-tag>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="视频" prop="video" width="100">
                <template #default="scope">
                    {{ scope.row.video ? '是' : '' }}
                </template>
            </el-table-column>
            <el-table-column label="上线时间" prop="online_date" width="150"></el-table-column>
            <el-table-column label="备注" prop="id" width="150"></el-table-column>
            <el-table-column label="操作" width="200">
                <template #default="scope">
                    <el-button type="primary" size="small" :disabled="scope.row.online_date || loading"
                               @click="online(scope.row)">上线
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination layout="prev, pager, next" :total="total" @current-change="fetchData"
                       :page-size="query.limit"></el-pagination>
        <template #footer>
            <el-button type="primary" @click="reset" :disabled="loading">关闭</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {VueDraggableNext} from 'vue-draggable-next'
import {timestampToDate} from "../libs/utils";

export default {
    name: "Update",
    components: {VueDraggableNext},
    data() {
        return {
            loading: false, data: [], dialog_opened: false, category_list: [], selected: [],
            today: timestampToDate(Date.now()), total: 0, date: new Date(), page_data: [],
            query: {
                module: 1,
                online: null,
                skip: 0,
                limit: 100,
                video: null,
                id: null,
            },
            form: {
                date: '',
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            const month = this.date.getMonth() + 1 + '';
            axios.get(`/cms/v1/image`, {
                params: {
                    module: 1,
                    start: `${this.date.getFullYear()}-${month.length > 1 ? month : '0' + month}-01`,
                    end: `${this.date.getFullYear()}-${month.length > 1 ? month : '0' + month}-31`,
                    limit: 1000,
                }
            }).then(res => {
                this.data = res.data.data.imageList;
                this.total = res.data.data.total;
                if (this.dialog_opened) {
                    this.selected = this.data.filter(d => d.online_date === this.form.date).sort((a, b) => a.sort < b.sort ? -1 : 1);
                }
                this.loading = false;
            })
        },
        fetchData(page) {
            this.query.skip = page - 1;
            this.loading = true;
            axios.get(`/cms/v1/image`, {params: this.query}).then(res => {
                this.page_data = res.data.data.imageList;
                this.total = res.data.data.total;
                this.loading = false;
            })
        },
        reset() {
            this.loading = this.dialog_opened = false;
        },
        openDialog(date) {
            this.form.date = date;
            this.dialog_opened = true;
            this.fetchData(1);
            this.selected = this.data.filter(d => d.online_date === date).sort((a, b) => a.sort < b.sort ? -1 : 1);
        },
        online(image) {
            this.loading = true;
            image.online_date = this.form.date;
            axios.put(`/cms/v1/image/${image.id}`, image).then(res => {
                this.selected.push(image);
                this.data.push(image);
                this.loading = false;
            })
        },
        offline(image) {
            this.loading = true;
            image.online_date = null;
            axios.put(`/cms/v1/image/${image.id}`, image).then(res => {
                this.selected.splice(this.selected.indexOf(image), 1);
                this.data.splice(this.data.indexOf(image), 1);
                this.loading = false;
            })
        },
        updateVideo(row) {
            this.loading = true;
            row.video = !row.video;
            axios.put(`/cms/v1/image/${row.id}`, row).then(res => {
                this.loading = false;
            });
        },
        sort() {
            const sort = [];
            this.selected.forEach((d, i) => {
                sort.push(d.id);
                d.sort = i;
            });
            this.loading = true;
            axios.post(`/cms/v1/sort/image`, {sort}).then(res => {
                this.loading = false;
            })
        },
        selectDate(value) {
            this.$refs.calendar.selectDate(value);
        }
    },
    mounted() {
        axios.get('/cms/v1/category').then(res => {
            this.category_list = res.data.data.categoryList;
            this.init();
        });
    },
    watch: {
        date(latest, previous) {
            if (latest.getMonth() !== previous.getMonth()) {
                this.init();
            }
        }
    }
}
</script>

<style scoped>
.el-calendar ::v-deep(.el-calendar-day) {
    height: fit-content;
    width: 160px;
    min-height: var(--el-calendar-cell-width);
}
</style>